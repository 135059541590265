import DayOfWeek from "./DayOfWeek";

const Projects = () => {
   return(
      <article>
         <h1 className='about'>These are my personal projects</h1>
            <div align='center' style={{padding:'2rem'}}>
               <DayOfWeek/>
            </div>
      </article>

   )
}
 
export default Projects;
function calculateWeek(e){
    e.preventDefault();
    let week_day = 0;
    let doom_day = 0;
    let weekday = "";
    let month = parseInt(e.target.month.value);
    let day = parseInt(e.target.day.value);
    let year = parseInt(e.target.year.value);
    let closest_century = parseInt(year/100);
    closest_century *= 100;
    let century_check = (closest_century % 400)/100;
    let year_in_century = year - closest_century;
    let leap_year = year_in_century%4;
    let year_check = year_in_century/12;
    let remaining_years = year_in_century%12;
    let final = remaining_years / 4;
    switch(century_check){
    case 0: 
        week_day = 2;
        break;
    case 1: 
        week_day = 0;
        break;
    case 2: 
        week_day = 5;
        break;
    case 3: 
        week_day = 3;
        break;
    default:
        break;
    }
    let total = parseInt(week_day + year_check + remaining_years + final);
    total %= 7;

    switch(month){
    case 1:
        if (leap_year === 0) doom_day = 4;
        else doom_day = 3;
        break;
    case 2:
        if (leap_year === 0) doom_day = 29;
        else doom_day = 28;
        break;
    case 3:
            doom_day = 14;
            break;
    case 4:
            doom_day = 4;
            break;
    case 5:
            doom_day = 9;
            break;
    case 6:
            doom_day = 6;
            break;
    case 7:
            doom_day = 11;
            break;
    case 8:
            doom_day = 8;
            break;
    case 9:
            doom_day = 5;
            break;
    case 10:
             doom_day = 10;
             break;
    case 11:
             doom_day = 7;
             break;
    case 12:
             doom_day = 12;
             break;
    default:
        break;
         
    }
let final_weekday = total +(((day) - doom_day)%7)
if (final_weekday >= 7)final_weekday -= 7;
switch(final_weekday){
    case 0:
        weekday = "The chosen date is on a Sunday";
        break;
    case 1:
        weekday = "The chosen date is on a Monday";
        break;
    case 2:
        weekday = "The chosen date is on a Tuesday";
        break;
    case 3:
        weekday = "The chosen date is on a Wednesday";
        break;
    case 4:
        weekday = "The chosen date is on a Thursday";
        break;
    case 5:
        weekday = "The chosen date is on a Friday";
        break;
    case 6:
        weekday = "The chosen date is on a Saturday";
        break;
    default:
        break;
}
if ((month === 2 && leap_year === 0 && day > 29)||(month === 2 && leap_year !== 0 && day > 28)) alert("Enter a valid date");
else if ((month === 4||month === 6||month === 9||month === 11) && day > 30)alert("Enter a valid date");
else if (year < 1582)alert("This date is from before the modern day calendar existed in 1582, please enter a date after this year");
else alert(weekday);
document.getElementById("calculate").reset();

}

const DayOfWeek = () => {
    return ( 
        <article>
        <h2>Day of the week calculator</h2>
        <form onSubmit={calculateWeek} id="calculate">
            <input type="number" placeholder="Month" name="month" max={12} />
            <input type="number" placeholder="Day" name="day" max={31} />
            <input type="number" placeholder="Year" name="year" max={10000} min={0} />
            <input type="submit" />
        </form>
        </article>
        
     );
}
 
export default DayOfWeek;
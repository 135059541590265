import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import { FaTimes, FaBars } from 'react-icons/fa';
import '../assets/styles/navbar.scss';

const Navbar = ({ theme, toggleTheme }) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <nav className='navMenu'>
      <div onClick={handleClick} className='menu-icon'>
        {clicked ? <FaTimes /> : <FaBars />}
      </div>
      <ul className={clicked ? 'navList active' : 'navList'}>
        <li><Link to="/home">Home</Link></li>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/work">Work</Link></li>
        <li><Link to="/projects">Projects</Link></li>
        <li><Link to="/contact">Contact</Link></li>
        
        </ul>
      <Switch
        onChange={toggleTheme}
        checked={theme === 'dark'}
        uncheckedIcon={false}
        checkedIcon={false}
        onColor="#000"
        offColor="#ddd"
      />
    </nav>
  );
};

export default Navbar;
const start = new Date();
const today = new Date();
const current = Math.floor((today - start)/(3.1556952 * Math.pow(10,10)));
const Jobs = [
    {
        job:'Executive Flight Center',
        position:'Ramp Agent',
        employment:'Sept 2023 - Nov 2023',
        duration:'2 Months',
        location:'Calgary AB, Canada',
        duties:[
            'Facilitated the safe landing and takeoff of at least 4 aircraft a day ensuring that any debris was out of the way.',
            'Handled passenger luggage and mobility aids to ensure they got to their destination.',
            'Walk alongside the wings of the airplane to identify and promptly communicate any' + 
            ' potential hazards to the marshaller in front of the aircraft during its arrival or departure.',
            'Ensured that all service vehicles were beside the hanger before the aircraft landed.'
        ]
    },
    {
        job:'Cineplex Entertainment',
        position:'Team Member/Team Leader',
        employment:'Oct 2015 - Sept 2023 ',
        duration:'8 Years',
        location:'Calgary AB, Canada',
        duties:[
            "Led and supervised a team of upwards of 10 people ensuring that they completed their tasks in a timely manner.",
            "Diagnosed and resolved complex customer issues decreasing overall resolution " +
            "time by 40% and improved customer satisfaction scores by 25%",
            "Trained the new employees to bring them up to speed on how things should be done, also helping them with any questions they may have.",
            "Prioritized maintaining a clean work area for a safe and pleasant customer experience."
        ]
    },
    {
        job:'GAO Tek',
        position:'Software Development Intern',
        employment:'August 2021 - November 2021',
        duration: '3 Months',
        location:'Toronto ON, Canada (remote)',
        duties:[
            "Collaborated with a team of 6 to develop an application which achieved the company's smooth transition from fax machines to a virtual space. ",
            "Produced programming documents to ensure that the program was done efficiently and on schedule.",
            "Attained the ability to work independently in a timely manner"
        ]
    }
]
export default Jobs;
import '../assets/styles/App.scss'
import React, { useState,useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './themes';
import Navbar from './Navbar';
import WorkExperience from './Work/WorkExperience';
import ContactMe from './ContactMe';
import Home from './Home';
import Projects from './Projects/Projects';
import Error from './Error';
import AboutMe from './AboutMe';
import { BrowserRouter , Route ,Routes } from 'react-router-dom';
import '../assets/styles/global.scss';
import '../assets/styles/App.scss';
import {FaRegCopyright} from 'react-icons/fa';

const App = () => {
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  useEffect(() => {
    const root = document.documentElement;
    if (theme === 'light') {
      root.style.setProperty('--body-bg', lightTheme.body);
      root.style.setProperty('--text-color', lightTheme.text);
      root.style.setProperty('--nav-bg', lightTheme.background);
      root.style.setProperty('--nav-text', lightTheme.text);
      root.style.setProperty('--link-color', 'var(--light-link-color)');
      root.style.setProperty('--link-hover', 'var(--light-link-hover)');
      root.style.setProperty('--link-clicked', 'var(--light-link-clicked)');
    } else if(theme === 'dark') {
      root.style.setProperty('--body-bg', darkTheme.body);
      root.style.setProperty('--text-color', darkTheme.text);
      root.style.setProperty('--nav-bg', darkTheme.background);
      root.style.setProperty('--nav-text', darkTheme.text);
      root.style.setProperty('--link-color', 'var(--dark-link-color)');
      root.style.setProperty('--link-hover', 'var(--dark-link-hover)');
      root.style.setProperty('--link-clicked', 'var(--dark-link-clicked)');
    }
    else{
      <Error/>
    }
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <BrowserRouter>
        <Navbar theme={theme} toggleTheme={toggleTheme} />
        <div className="app-container">
          <Routes>
                  <Route path='/' element={<Home/>}/>
                  <Route path='/home' element={<Home/>} />
                  <Route path='/about' element={<AboutMe/>} />
                  <Route path='/work' element={<WorkExperience/>} />
                  <Route path='/contact' element={<ContactMe/>} />
                  <Route path='/projects' element={<Projects/>} />
                  <Route path= '*' element={<Error/>} />
          </Routes>
        </div>
        <label className='copyright'><FaRegCopyright/>Zachary Brisbin 2024</label>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
import '../../assets/styles/WorkExperience.scss'
import Jobs from './Jobs'
const WorkExperience = () =>{
        return(
            <article>
                <h1>Work Experience</h1>
                {Jobs.map ((job,index) =>{
                return(       
                <div key={index} className='jobs'> 
                    <p className="work"> {job.job}</p>
                    <p className="side">Position: {job.position} <br/>
                        {job.employment} <br/>
                        Employment Duration: {job.duration}<br/>
                        Location: {job.location} <br/>
                    </p>
                    <ul className='duties'>
                        {job.duties.map ((duty,index) =>{
                            return(
                                    <li  key={index}>{duty}</li>   
                            ) 
                        })}
                    </ul>
                </div>
                )
                })}
            </article>
        )
}
export default WorkExperience;
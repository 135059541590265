
import '../assets/styles/Contact.scss'
import'../assets/styles/Articles.scss'
const ContactMe = () =>{
        return ( 
            <article>
                <h1>Contact Me</h1>
                <form action="mailto:zacharybrisbin@rocketmail.com?subject=Inquiry" method="post" encType='text/plain'>
                    <div className='contact'>
                            <input type='text' placeholder='First Name'  name='fname' id='fname' class='contact_input'/>
                    </div>
                    <div class='contact'>
                        <input type='text' placeholder='Last Name' name='lname' id='lname' class='contact_input' />
                    </div>
                    <div class='contact'>
                        <input type='email' placeholder='Email' name='email' id='email' class='contact_input'/>
                    </div>
                    <div class='contact'>
                        <textarea cols='30' rows='10' placeholder='Enter your message' name='message' id='message' class='contact_input'></textarea>
                    </div>
                    <div class='contact'>
                        <button type='submit' className='contact_input'>Send Email</button>
                    </div>

                </form>
                
            </article>
         );
    }

 
export default ContactMe;
import '../assets/styles/Error.scss'
import  error from '../assets/Photos/Error.png'
const Error = () => {
        return ( 
            <div>
                <h1 className='error-message'>You have tried going to a page that does not exist</h1>
                <img src={error} alt='Hand' className='error-image' />
            </div>
         );
}
 
export default Error;
import father from '../assets/Photos/Father.jpg'
import guiness from '../assets/Photos/Guiness.jpg'
import boat from '../assets/Photos/Boat.jpeg'
import '../assets/styles/Articles.scss'
const AboutMe = () => {
        const start = new Date("August 29, 1997");
        const today = new Date();
        const current = Math.floor((today - start)/(3.1556952 * Math.pow(10,10)));
        return(
            <article>
                <h1>About Me</h1>
                <p className='about'>
                    I am Zachary Brisbin, a {current} year old guy from Calgary, Alberta that recently graduated from the
                    IT program at SAIT. My strongest subjects were web design and development, as well as database management
                    and administration. I have worked with Java, Javascript, MySQL, Oracle Database, GraphQL, and currently this 
                    site is being built using React with SASS for styling. I chose this career path due to the challenge that it presents every day, 
                    in finding a good solution to a problem that needs to be overcome, learning new ways to do things that 
                    I haven't thought of before from someone more experienced than me, or even just learning from my own mistakes,
                    these things all help me to keep on wanting to go furter for myself and all those around me. In my personal life 
                    I enjoy hiking in the mountains, playing video games, solving twisty puzzles (like the rubiks cube), hanging with 
                    my buds, and watching movies.
                </p>
                <img src={boat} alt='Boat' />
                <img src={father} alt='Father and Me'/>
                <img src={guiness} alt='Me At the Guiness Brewery' />
            </article>
        )
}
export default AboutMe;
import { Link } from 'react-router-dom'
import '../assets/styles/Home.scss'
import Paragraph from './Paragraph'
import { FaFileDownload } from 'react-icons/fa';
import resume from '../assets/Resume.pdf'
const Home = () =>{
        return(
            <article>
                <h1 className='welcome'>Welcome to my site !!!</h1>
                <a href={resume} download className='resume'><FaFileDownload size='30' className='symbol'/>My Resume</a>
                <p>
                    <h2><Link to='/about' className='link'>About Me</Link></h2>
                    <Paragraph paragraph='This page outlines a bit about me and who I am in what I have learned as well as some of the 
                    hobbies I do in my personal time.' />
                </p><br />
                <p>
                    <h2><Link to='/work' className='link'>Work Experience</Link></h2>
                    <Paragraph paragraph='This page talks about my current and previous work experience that I have done throughout my 
                    years and what was required of me at each of those previous jobs.' />
                </p>
                <p>
                    <h2><Link to='/contact' className='link'>Contact Me</Link></h2>
                    <Paragraph paragraph='This page allows you to directly contact me using an online form for any inquiries or
                    job offers.' />
                </p>
            </article>
        );
}
export default Home;
export const lightTheme = {
  body: '#FFF',
  text: '#000',
  background: '#f8f9fa',
};

export const darkTheme = {
  body: '#363537',
  text: '#FAFAFA',
  background: '#3a3b3c',
};
